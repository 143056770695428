import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getConfig } from '../../config/getConfig';
import { LoaderPage } from '../../components/LoaderPage/LoaderPage';
import { storeAuthParams } from './utils';

export type StoredAuthParams = {
  codeChallenge: string;
  redirectUri: string;
  expiresAt: number;
};

export const ExtensionSignUp = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const codeChallenge = searchParams.get('code_challenge');
    const redirectUri = searchParams.get('redirect_uri');

    codeChallenge && redirectUri && storeAuthParams({ codeChallenge, redirectUri });

    const {
      frontegg: { apiUrl },
    } = getConfig();

    window.location.replace(`${apiUrl}/oauth/account/sign-up`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoaderPage />;
};

import { Outlet } from 'react-router-dom';
import { ContextOptions } from '@frontegg/types/ContextOptions';
import { AuthOptions } from '@frontegg/types/FronteggStoreOptions';
import { FronteggProvider } from '@frontegg/react';
import { adminPortalThemeOptions } from '../AdminPanelView/AdminPanelView';
import { getConfig } from '../../config/getConfig';
import { useEffect } from 'react';

const config = getConfig();

const baseContextOptions: ContextOptions = {
  baseUrl: config.frontegg.apiUrl,
  clientId: config.frontegg.clientId,
};

const authOptions: AuthOptions = {
  keepSessionAlive: true,
  hostedLoginOptions: {
    loadUserOnFirstLoad: true,
  },
};

const useHubspotTrackingScript = () => {
  useEffect(() => {
    const scriptUrl = config.hsTrackingScriptUrl;

    if (scriptUrl) {
      const script = document.createElement('script');

      const scriptAttrs = {
        src: scriptUrl,
        type: 'text/javascript',
        async: true,
        defer: true,
        id: 'hs-script-loader',
      };
      Object.assign(script, scriptAttrs);

      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);
};

export const RootLayout = () => {
  useHubspotTrackingScript();

  return (
    <FronteggProvider
      contextOptions={baseContextOptions}
      hostedLoginBox={true}
      authOptions={authOptions}
      themeOptions={{
        adminPortal: adminPortalThemeOptions,
      }}
      entitlementsOptions={{ enabled: true }}
    >
      <Outlet />
    </FronteggProvider>
  );
};

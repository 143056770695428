import devConfig from './config.dev';
import { IConfig } from './config.interface';
import localConfig from './config.local';
import prodConfig from './config.prod';
import qaConfig from './config.qa';

type ProfileEnv = 'dev' | 'local' | 'prod' | 'qa';

const envToConfigMap: Record<ProfileEnv, IConfig> = {
  dev: devConfig,
  local: localConfig,
  prod: prodConfig,
  qa: qaConfig,
};

const isValidEnv = (env: string): env is ProfileEnv => {
  return env in envToConfigMap;
};

export const profileEnv = (process.env.REACT_APP_FORGOOD_PROFILE || 'dev') as ProfileEnv;

export const getConfig = () => {
  if (isValidEnv(profileEnv)) {
    return envToConfigMap[profileEnv];
  }

  throw new Error(`Failed to find config for profile env: ${profileEnv}`);
};

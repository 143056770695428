import { useNavigate, useSearchParams } from 'react-router-dom';
import { getConfig } from '../../config/getConfig';
import { LoaderPage } from '../../components/LoaderPage/LoaderPage';
import { clearStoredAuthParams } from './utils';
import { readStoredAuthParams } from './utils';
import { useEffect } from 'react';
import { isSafeRedirectUrl } from '../../utils/isSafeRedirectUrl';

export const useAuthParams = (): { codeChallenge: string; redirectUri: string } => {
  const [searchParams] = useSearchParams();

  const codeChallenge = searchParams.get('code_challenge');
  const redirectUri = searchParams.get('redirect_uri');
  if (codeChallenge && redirectUri) {
    return { codeChallenge, redirectUri };
  }

  const authParams = readStoredAuthParams();
  if (!authParams) {
    throw new Error('Unable to get auth params');
  }

  return authParams;
};

export const ExtensionSignIn = () => {
  const { codeChallenge, redirectUri } = useAuthParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isSafeRedirectUrl(redirectUri)) {
      navigate(`/unsafe-redirect-url?unsafeUrl=${redirectUri}`);
      return;
    }

    const {
      frontegg: { clientId, apiUrl },
      extAuthSuccessRedirectUri,
    } = getConfig();

    const authorizeUrl = new URL(`${apiUrl}/oauth/authorize`);

    const state = JSON.stringify({
      redirectUri: redirectUri,
    });
    authorizeUrl.searchParams.append('state', state);
    authorizeUrl.searchParams.append('response_type', 'code');
    authorizeUrl.searchParams.append('client_id', clientId);
    authorizeUrl.searchParams.append('scope', 'openid+profile+email');
    authorizeUrl.searchParams.append('redirect_uri', extAuthSuccessRedirectUri);
    authorizeUrl.searchParams.append('code_challenge', codeChallenge);

    clearStoredAuthParams();

    window.location.replace(authorizeUrl.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoaderPage />;
};

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Title } from '../../components/common/Title';
import { Text } from '../../components/common/Text';
import { PageContainer } from '../../components/common/Layout';
import { Button } from '../../components/common/Button';
import { useAuthUser } from '@frontegg/react';
import Header from '../../components/PortalLayout/Header';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  height: 500px;

  padding: 64px;
  background-color: #fff;
  border-radius: 16px;
`;

export const AfterSignInMessage = () => {
  const user = useAuthUser();
  const navigate = useNavigate();

  return (
    <PageContainer>
      <Header />
      <Wrapper>
        <Title data-test-id="after-sign-in-message-title">Welcome, {user?.name}!</Title>
        <Text>Now you can close this page and return to your IDE.</Text>
        <Button $widthType="auto" $variant="secondary" onClick={() => navigate('/dashboard')}>
          Open Admin Panel
        </Button>
      </Wrapper>
    </PageContainer>
  );
};

import { useAuth } from '@frontegg/react';
import { useMemo } from 'react';
import {
  getExternalRedirectUrl,
  RedirectToView,
} from '../components/RedirectToView/RedirectToView';
import TermsOfService from '../components/TermsOfServiceModal/TermsOfService';
import { LoginBoxView } from '../components/LoginBoxView/LoginBoxView';
import { Navigate } from 'react-router-dom';
import { useAttachUtm } from '../hooks/useAttachUtm';
import { readStoredAuthParams } from './ExtensionAuth/utils';

export function AuthPage() {
  useAttachUtm();

  const { user, isAuthenticated } = useAuth();
  const redirectUrl = useMemo(() => getExternalRedirectUrl(), []);

  const userMetadata = useMemo(() => {
    if (!user || !user.metadata) {
      return null;
    }
    try {
      return JSON.parse(user.metadata) as { lastTermsCheck: string; welcomePageShown: boolean };
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [user]);

  const termsAcceptedDate = useMemo(() => {
    if (!userMetadata?.lastTermsCheck) {
      return null;
    }

    return new Date(userMetadata.lastTermsCheck);
  }, [userMetadata]);

  const showWelcomeScreen = useMemo(() => {
    if (userMetadata?.welcomePageShown || !termsAcceptedDate) {
      return false;
    }

    // Check if terms was accepted ealrier than a day ago
    const dayMs = 24 * 60 * 60 * 1000;
    const signedUpDayAgo = termsAcceptedDate?.getTime() + dayMs <= Date.now();

    return !signedUpDayAgo;
  }, [termsAcceptedDate, userMetadata]);

  // This is auth params for new extension auth flow.
  // It's stored in localStorage and used only once after signup
  const hasStoredAuthParams = readStoredAuthParams() !== null;

  if (!isAuthenticated) {
    return <LoginBoxView />;
  } else if (!termsAcceptedDate) {
    return <TermsOfService />;
  } else if (!!redirectUrl) {
    return <RedirectToView />;
  } else if (hasStoredAuthParams) {
    return <Navigate to="/extension/signin" />;
  } else if (!!showWelcomeScreen) {
    return <Navigate to="/welcome" />;
  } else {
    return <Navigate to="/dashboard" />;
  }
}

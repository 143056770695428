import { UTMParamKey } from './hooks/useStoreUtmParams';

export const LOCAL_STORAGE_KEYS = {
  originUrl: 'originUrl',
  authParams: 'authParams',
};

export type LocalStorageKeys =
  | (typeof LOCAL_STORAGE_KEYS)[keyof typeof LOCAL_STORAGE_KEYS]
  | UTMParamKey;

import { useAuthUser } from '@frontegg/react';
import { LoaderPage } from '../LoaderPage/LoaderPage';
import { useEffect, useState } from 'react';
import { isSafeRedirectUrl } from '../../utils/isSafeRedirectUrl';
import { Navigate } from 'react-router-dom';
import { LOCAL_STORAGE_KEYS } from '../../constants';
import * as Sentry from '@sentry/react';

const maskToken = (token?: string) => (token ? `${token.slice(0, 4)}...${token.slice(-4)}` : '');

export function RedirectToView() {
  const { accessToken, refreshToken, id, email } = useAuthUser();
  const [isRedirected, setIsRedirected] = useState(false);

  const redirectTo = getRedirectUrl(accessToken, refreshToken, { id, email });

  const isSafeRedirect = redirectTo ? isSafeRedirectUrl(redirectTo) : false;

  useEffect(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.originUrl);

    if (redirectTo && isSafeRedirect) {
      const handler = () => {
        window.addEventListener('focus', () => {
          setIsRedirected(true);
          window.close();
        });
      };
      window.addEventListener('blur', handler);

      window.location.replace(redirectTo);
      return () => {
        window.removeEventListener('blur', handler);
      };
    } else if (!redirectTo) {
      setIsRedirected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectTo, isSafeRedirect]);

  if (isRedirected) {
    return <div style={{ margin: '0.5rem' }}>Now you can close this window...</div>;
  }

  if (redirectTo && !isSafeRedirect) {
    return <Navigate to={`/unsafe-redirect-url?unsafeUrl=${redirectTo}`} />;
  }

  return <LoaderPage />;
}

function getRedirectUrl(
  authToken?: string,
  refreshToken?: string,
  user?: { id: string; email: string }
) {
  const redirectTo = getExternalRedirectUrl();
  if (!redirectTo || !authToken) {
    return null;
  }
  const url = new URL(redirectTo);
  url.searchParams.append('authToken', authToken);
  if (refreshToken) {
    url.searchParams.append('refreshToken', refreshToken);
  }

  const maskedAccessToken = maskToken(authToken);
  const maskedRefresh = maskToken(refreshToken);

  Sentry.captureMessage(
    `Serving tokens to ${redirectTo}. Access Token = ${maskedAccessToken}, Refresh token = ${maskedRefresh}. User ID = ${user?.id} Email = ${user?.email}.`
  );

  return url.toString();
}

export function getExternalRedirectUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('redirectTo')) {
    return urlParams.get('redirectTo');
  }
  const originUrlString = localStorage.getItem(LOCAL_STORAGE_KEYS.originUrl);
  if (originUrlString) {
    const originUrl = new URL(originUrlString);
    return originUrl.searchParams.get('redirectTo');
  }
  return null;
}

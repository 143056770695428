import { LOCAL_STORAGE_KEYS } from '../../constants';
import { StoredAuthParams } from './ExtensionSignUp';

export const readStoredAuthParams = (): Omit<StoredAuthParams, 'expiresAt'> | null => {
  const authParamsString = localStorage.getItem(LOCAL_STORAGE_KEYS.authParams);

  if (!authParamsString) return null;

  try {
    const { redirectUri, codeChallenge, expiresAt } = JSON.parse(
      authParamsString
    ) as StoredAuthParams;

    if (!redirectUri || !codeChallenge) {
      return null;
    }

    if (expiresAt && expiresAt <= Date.now()) {
      return null;
    }

    return { redirectUri, codeChallenge };
  } catch (error) {
    return null;
  }
};

export const clearStoredAuthParams = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.authParams);
};

export const storeAuthParams = (
  params: Pick<StoredAuthParams, 'redirectUri' | 'codeChallenge'>
): void => {
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.authParams,
    JSON.stringify({
      ...params,
      expiresAt: Date.now() + 10 * 60 * 1000, // Expires in 10 minutes
    })
  );
};

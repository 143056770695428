import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { isSafeRedirectUrl } from '../../utils/isSafeRedirectUrl';
import { useEffect, useRef } from 'react';
import { LoaderPage } from '../../components/LoaderPage/LoaderPage';

type RedirectToExtensionProps = {
  code: string;
  uri: string;
};

const RedirectToExtension = ({ code, uri }: RedirectToExtensionProps) => {
  const isRedirected = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isRedirected.current) return;

    // Uri need to be decoded before appending the code param.
    const decodedUri = decodeURIComponent(uri);

    const redirectUrl = new URL(decodedUri);
    redirectUrl.searchParams.append('code', code);
    const redirectUri = redirectUrl.toString();

    window.location.replace(redirectUri);
    isRedirected.current = true;

    navigate('/extension/after-signin');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoaderPage />;
};

export const AuthSuccess = () => {
  const [searchParams] = useSearchParams();

  const code = searchParams.get('code');
  const state = searchParams.get('state');

  if (!code || !state) throw new Error('Invalid params');

  const { redirectUri } = JSON.parse(state) as { redirectUri?: string };

  if (!redirectUri) throw new Error('Missing redirectUri');

  if (!isSafeRedirectUrl(redirectUri)) {
    return <Navigate to={`/unsafe-redirect-url?unsafeUrl=${redirectUri}`} />;
  }

  return <RedirectToExtension code={code} uri={redirectUri} />;
};

const safeUris = [
  'vscode://zencoderai.zencoder',
  'vscode-insiders://zencoderai.zencoder',
  'vscodium://zencoderai.zencoder',
  'cursor://zencoderai.zencoder',
  'windsurf://zencoderai.zencoder',
  'http://127.0.0.1',
];

// We have to use regexp to parse the custom protocol URL
// because not all browsers work well with custom protocol URLs.
// For example, Firefox fails to parse host and hostname.
const parseCustomProtocolUrl = (url: string) => {
  const urlPattern = /^([a-zA-Z\d+-]+):\/\/?([.a-zA-Z\d]+)[:/?#]*/;
  const match = url.match(urlPattern);

  if (!match) return {};

  const [, protocol, host] = match;
  return { protocol, host };
};

export const isSafeRedirectUrl = (url: string) => {
  if (!url) return false;
  const { protocol, host } = parseCustomProtocolUrl(url);

  return safeUris.some((safeUri) => {
    return safeUri === `${protocol}://${host}`;
  });
};

import { IConfig } from './config.interface';

const prodConfig: IConfig = {
  frontegg: {
    apiUrl: 'https://fe.zencoder.ai',
    clientId: '838f5ae1-55fe-424d-864b-2fa4e066af26',
  },
  apiBaseUrl: 'https://auth.zencoder.ai',
  extAuthSuccessRedirectUri: 'https://auth.zencoder.ai/extension/auth-success',
  stripePubKey:
    'pk_live_51PAvvvKID1k3Z3c14UMoe9dVEA1dBzqwfBxH7rsp9t4qq8RtH8YrBuPjFtRhKGie1UmkaSSSoaDOOBC6w59eg4Iu00eNY1oSj2',
  hsTrackingScriptUrl: '//js.hs-scripts.com/46014728.js',
};

export default prodConfig;

import { IConfig } from './config.interface';

const qaConfig: IConfig = {
  frontegg: {
    apiUrl: 'https://qa.fe.zencoder.ai',
    clientId: '015fdfd3-77f7-415e-b72f-9f5863a63e63',
  },
  apiBaseUrl: 'https://qa.auth.zencoder.ai',
  extAuthSuccessRedirectUri: 'https://qa.auth.zencoder.ai/extension/auth-success',
  stripePubKey:
    'pk_test_51PAvvvKID1k3Z3c1MSzN0EHAl2QjMJikcr3Y95sBgHfqFyt34MYEs07uPtg08nWvFpgaJaCy8o3CDpj4OLgMU6tP00qq1mBY2K',
};

export default qaConfig;
